// extracted by mini-css-extract-plugin
export var method = "method-module--method--8ewmq";
export var discipline = "method-module--discipline--PFhiz";
export var overview = "method-module--overview--tUTmM";
export var hero = "method-module--hero--Iat8t";
export var header = "method-module--header--dezDb";
export var noEyebrow = "method-module--no-eyebrow--U7jtm";
export var description = "method-module--description--u4j8R";
export var disciplineSummary = "method-module--discipline-summary--8W5I1";
export var details = "method-module--details--UxLn-";
export var outcomes = "method-module--outcomes--o+XnD";
export var steps = "method-module--steps--R7Jo8";
export var resourceCards = "method-module--resource-cards--+vthk";
export var sources = "method-module--sources--eNZT6";